const baseStyle = {
  borderRadius: 'radii.1'
}

const appearances = {}
const sizes = {}

export default {
  baseStyle,
  appearances,
  sizes
}
